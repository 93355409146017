var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Autocomplete',_vm._b({on:{"change":_vm.updateValue}},'Autocomplete',{
    ..._vm.$props,
    options: _vm.options,
    value: _vm.newValue,
    noFilter: false,
    returnObject: false,
    clearable: true,
    autoSelectFirst: true
  },false))
}
var staticRenderFns = []

export { render, staticRenderFns }