<template>
  <Autocomplete 
    v-bind="{
      ...$props,
      options,
      value: newValue,
      noFilter: false,
      returnObject: false,
      clearable: true,
      autoSelectFirst: true
    }"
    @change="updateValue"
  >
  </Autocomplete>
  
</template>

<script>
import Autocomplete from '@/components/form/Autocomplete.vue'
import { Occupations } from '@/scripts/options'

export default {
  name: "OccupationAutocomplete",
  components: {
    Autocomplete
  },
  data() {
    return {
      newValue: null,
      options: []
    }
  },
  props: {
    value: String,
    rules: String,
    label: String,
    clearable: Boolean,
    required: Boolean,
    errors: Array,
    disabled: Boolean,
    name: String,
    module: String
  },
  emits: [
    'change'
  ],
  methods: {
      updateValue(v) {
        if (v) {
          this.newValue = v;
        }
        else {
          this.newValue = null;
        }

        this.emitValue();
      },
      emitValue() {
          this.$emit('change', this.newValue)       
      },
  },
  mounted() {
    const occupations = Occupations;
    occupations.sort();
    this.options = occupations.map(o => {
      return {
        value: o,
        text: o
      }
    });
  },
  watch: {
      value: {
          immediate: true,
          deep: true,
          handler(newVal) {
            if (this.newValue != newVal) {
              this.newValue = newVal;
            }
          }
      }
  },
}
</script>
